import {
    Livewire,
    Alpine,
} from '../../vendor/livewire/livewire/dist/livewire.esm'

import tippy, { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import oldBrowserDetector from 'old-browser-detector'
import datepicker from 'js-datepicker'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        tippy(el, {
            content: expression,
            allowHTML: true,
            theme: 'dark',
            zIndex: 99999,
            arrow: false,
            placement: 'top-start',
            followCursor: true,
            plugins: [followCursor],
            onShow(instance) {
                if (!el.getAttribute('enable-x-tooltip')) {
                    return false
                }
            },
        })
    });

    Alpine.data('badge', () => {
        return {
            showPopup: false,
            init() {
                const body = document.querySelector('body:not(.click-active)');

                if (body) {
                    body.addEventListener('click', (e) => {
                        const activeBadge = document.querySelector('.badge__wrapper.badge__popup-active');

                        if (activeBadge && (!activeBadge.contains(e.target) || (activeBadge.contains(e.target) && !activeBadge.querySelector('.badge__content').contains(e.target)))) {
            
                            setTimeout(() => {
                                activeBadge.classList.remove('badge__popup-active');             
                            }, 60)
                        }

                    });
                    body.classList.add('click-active');
                }
            },
            handlePopup(e) {
                if (!document.querySelector('.badge__wrapper.badge__popup-active')) {
                    e.target.closest('.badge__wrapper').classList.add('badge__popup-active');
                }
            }
        }
    });
    
})

Livewire.start()

/**
 * Old browser detector
 */

const Detector = new oldBrowserDetector({ i: 13 })

const isNewBrowser = Detector.detect() !== true
window.addEventListener('load', (w) => {
    document.getElementsByClassName('old-browser').forEach((el) => {
        if (!isNewBrowser) {
            el.className = el.className.replace(/ *old-browser--hidden/, '')
        }

        el.getElementsByClassName('old-browser__close').forEach((btnEl) => {
            btnEl.addEventListener('click', (e) => {
                let isInClass =
                    el.className.search('old-browser--hidden') !== -1
                if (!isInClass)
                    el.className = el.className + ' old-browser--hidden'
            })
        })
    })
})

function formatDate(e) {
    var date = e
    var day = date.getDate()
    var month = date.getMonth()
    month = month + 1
    if (String(day).length == 1) {
        day = '0' + day
    }
    if (String(month).length == 1) {
        month = '0' + month
    }
    var cleanDate = day + '/' + month + '/' + date.getFullYear()
    return cleanDate
}

function createDateObject(inputId) {
    let dateString = document.getElementById(inputId).value
    if (dateString) {
        let parts = dateString.split('/')
        return new Date(parts[2], parts[1] - 1, parts[0])
    } else {
        return null
    }
}

var filtersExist = document.getElementById('filters')
if (typeof filtersExist != 'undefined' && filtersExist != null) {
    const start_strength = datepicker('#strength_fromDate', {
        id: 1,
        formatter: (input, date, instance) => {
            input.value = formatDate(date)
        },
        dateSelected: createDateObject('strength_fromDate'),
        startDay: 1,
    })

    const end_strength = datepicker('#strength_tillDate', {
        id: 1,
        formatter: (input, date, instance) => {
            input.value = formatDate(date)
        },
        dateSelected: createDateObject('strength_tillDate'),
        startDay: 1,
        maxDate: new Date(),
    })

    const start_impact = datepicker('#impact_fromDate', {
        id: 2,
        formatter: (input, date, instance) => {
            input.value = formatDate(date)
        },
        dateSelected: createDateObject('impact_fromDate'),
        startDay: 1,
    })

    const end_impact = datepicker('#impact_tillDate', {
        id: 2,
        formatter: (input, date, instance) => {
            input.value = formatDate(date)
        },
        dateSelected: createDateObject('impact_tillDate'),
        startDay: 1,
        maxDate: new Date(),
    })

    document.querySelectorAll('.clear-datepicker').forEach((clearBtn) => {
        clearBtn.onclick = (e) => {
            start_strength.setDate(null)
            end_strength.setDate(null)
            start_impact.setDate(null)
            end_impact.setDate(null)
        }
    })
}

document.addEventListener('DOMContentLoaded', function () {
    setVerticalHeight()
})

window.addEventListener('resize', function () {
    setVerticalHeight()
})

function setVerticalHeight() {
    // Get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', vh + 'px')
}

if (document.querySelector('form.js-refresh-csrf-token')) {
    const form = document.querySelector('form.js-refresh-csrf-token')
    form.addEventListener('submit', async (evt) => {
        const isInApp =
            'standalone' in window.navigator && window.navigator.standalone

        if (!isInApp) return

        evt.preventDefault()

        const request = new XMLHttpRequest()
        request.addEventListener('load', function () {
            form.querySelector('input[name=_token]').value = this.responseText
            form.submit()
        })
        request.open('GET', '/csrf-token', true)
        request.send()
    })
}

var dataPercentage = document.getElementById('circular--progress')
// Only do the rest of the calculations/styling if the progress element is present
if (dataPercentage) {
    var dataPercentageValue = dataPercentage.getAttribute('data-pct')

    var circleBar = document.getElementById('bar--progress')
    var circleRadial = circleBar.getAttribute('r')
    var circleMath = Math.PI * (circleRadial * 2)

    if (dataPercentageValue < 0) {
        dataPercentageValue = 0
    }
    if (dataPercentageValue > 100) {
        dataPercentageValue = 100
    }

    var pct = ((100 - dataPercentageValue) / 100) * circleMath

    circleBar.style.strokeDashoffset = pct
}

